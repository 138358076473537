import API from 'o365.modules.data.api.ts';
import EventEmitter from 'o365.modules.EventEmitter.js';
import Alert from 'o365.controls.alert.ts';
import JsonDecoderStream from 'o365.modules.data.JsonDecoderStream.ts';

export interface IProcedureOptions {
    id: string;
    procedureName: string;
    useAlert?: boolean;
    useTransaction?: boolean;
    timeout?: number;
    useStreamRequest?: boolean;
}

interface IProcedureStateStorage {
    isLoading: boolean,
    isLoaded: boolean,
    executed: boolean,
    results: any
    error: any
}

type EventTypes = keyof typeof events;

const procedureStore = new Map<string, Procedure>();

const events = {
    BeforeExecute: 'BeforeExecute',
    AfterExecute: 'AfterExecute',
    Success: 'Success',
    Error: 'Error',
    ChunkLoaded: 'ChunkLoaded'
} as const

/** 
 * Get or create a procedure with the provided options. 
 * Returns raw, non-reactive Procedure instance.  
 * For reactive Procedure use the getOrCreateProcedure function from o365.vue.ts
 */
export function getOrCreateProcedure<T extends object = any>(pOptions: IProcedureOptions): Procedure<T> {
    if (!procedureStore.has(pOptions.id)) {
        new Procedure(pOptions);
    }
    return procedureStore.get(pOptions.id)!;
}


export default class Procedure<T extends object = any> {
    private readonly eventHandler = new EventEmitter();
    private abortController: AbortController | null = null;
    public readonly id: string;
    public readonly procedureName: string;
    public readonly useAlert: boolean;
    public readonly useTransaction: boolean;
    public readonly timeout: number;
    public readonly useStreamRequest: boolean;

    public readonly stateStorage = new Proxy<IProcedureStateStorage>({
        isLoading: false,
        isLoaded: false,
        executed: false,
        results: null,
        error: null
    }, {
        set: (obj: IProcedureStateStorage, prop: keyof IProcedureStateStorage, value: any) => {
            obj[prop] = value;

            return true;
        }
    });


    public get url() {
        return `/nt/api/data/${this.procedureName}`;
    }

    public get streamUrl() {
        return `/nt/api/data/stream/${this.procedureName}`;
    }

    public get state(){
        return this.stateStorage;
    }

    constructor(options: IProcedureOptions){
        this.id = options.id;
        this.procedureName = options.procedureName;
        this.useAlert = options.useAlert ?? true;
        this.useTransaction = options.useTransaction ?? true;
        this.timeout = options.timeout ?? 30;
        this.useStreamRequest = options.useStreamRequest ?? false;
        this.abortController = new AbortController();

        if (procedureStore.has(this.id)) {
            console.error('Procedure already exists: ', this.id);
            console.warn(`Please use getOrCreateProcedure() function instead of re-creating the same procedure:\n`+
            `import { getOrCreateProcedure } from 'o365.vue.ts';`);
            return;
        }
       
        procedureStore.set(this.id, this);
    }

    static getById(id: string): Procedure {
        if (!procedureStore.has(id)) {
            throw new Error('Procedure does not exist');
        }

        return procedureStore.get(id)!;
    }

    /** Executes the procedure and returns a promise of the result */
    async execute(values: T = {} as T) {
        if (this.useStreamRequest) {
            return await this.executeStream(values);
        }

        return await this.executeNormally(values);
    }

    private async executeNormally(values: T = {} as T): Promise<any> {
        this.stateStorage.isLoading = true;
        
        let options: {
            Operation: string;
            ProcedureName: string;
            UseTransaction: boolean;
            Timeout: number;
            Values?: T;
        } = {
            Operation: "execute",
            ProcedureName: this.procedureName,
            UseTransaction: this.useTransaction,
            Timeout: this.timeout
        };

        this.stateStorage.isLoaded = true;
        this.emit(events.BeforeExecute, options, values);

        options["Values"] = values;

        try {
            const response = await API.request({
                requestInfo: this.url,
                method: 'POST',
                showErrorDialog: false,
                body: JSON.stringify(options),
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-NT-API': 'true'}),
                abortSignal: this.abortController?.signal,
            });
            
            this.stateStorage.results = response;
            this.emit(events.Success, response);
            
            return response;
        } catch (error: any) {
            this.stateStorage.error = error;
            this.emit(events.Error, error);

            if (this.abortController?.signal.aborted) {
                const exceptionIsError = error instanceof Error;
                throw new AbortError('Request aborted', exceptionIsError ? error : undefined);
            }

            if (this.useAlert) {
                Alert(error);
            }

            if (typeof error === 'string') {
                error = new Error(error);
                error.skipVueHandler = true;
            } else {
                error.skipVueHandler = true;
            }
            
            throw error;
        } finally {
            this.stateStorage.isLoading = false;
            this.stateStorage.isLoaded = true;
            this.stateStorage.executed = true;

            this.emit(events.AfterExecute, {
                error: this.stateStorage.error,
                results: this.stateStorage.results
            });
        }
    }

    /** Executes the procedure and reads the response as a stream. The result is returned after the stream has completed, but the partial response is emitted on the PartialDataLoaded event */
    private async executeStream(values: T = {} as T): Promise<any> {
        this.stateStorage.isLoading = true;
        
        let options: {
            Operation: string;
            ProcedureName: string;
            UseTransaction: boolean;
            Timeout: number;
            Values?: T;
        } = {
            Operation: "execute",
            ProcedureName: this.procedureName,
            UseTransaction: this.useTransaction,
            Timeout: this.timeout
        };

        this.stateStorage.isLoaded = true;

        this.emit(events.BeforeExecute, options, values);

        options["Values"] = values;

        try {
            const response: Response = await API.request({
                requestInfo: this.streamUrl,
                method: 'POST',
                showErrorDialog: false,
                body: JSON.stringify(options),
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-NT-API': 'true',
                }),
                responseBodyHandler: false,
            });

            const reader = response.body?.getReader();

            if (reader === undefined) {
                throw new Error("Failed to read response");
            }

            const decoder = JsonDecoderStream();

            const result = new Array();
            
            let tableIndex: number | undefined = undefined;
            let currentRowKeys: Array<string> | undefined = undefined;

            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }

                decoder.decodeChunk(value, (item: any) => {
                    const itemKeys = Object.keys(item);

                    if (currentRowKeys === undefined || tableIndex === undefined) {
                        currentRowKeys = itemKeys;
                        tableIndex = 0;
                    } else if (currentRowKeys.length !== itemKeys.length || itemKeys.some((key) => !currentRowKeys!.includes(key))) {
                        currentRowKeys = itemKeys;
                        tableIndex++;
                    }

                    if (result.length < tableIndex + 1) {
                        result.push([]);
                    }

                    result[tableIndex].push(item);
                });

                this.stateStorage.results = result;
                this.emit(events.ChunkLoaded);
            }

            reader.releaseLock();
            
            this.stateStorage.results = result;
            this.emit(events.Success, result);
            
            return result;
        } catch (error: any) {
            this.stateStorage.error = error;
            this.emit(events.Error, error);

            if (this.useAlert) {
                Alert(error);
            }

            if (typeof error === 'string') {
                error = new Error(error);
                error.skipVueHandler = true;
            } else {
                error.skipVueHandler = true;
            }
            
            throw error;
        } finally {
            this.stateStorage.isLoading = false;
            this.stateStorage.isLoaded = true;
            this.stateStorage.executed = true;

            this.emit(events.AfterExecute, {
                error: this.stateStorage.error,
                results: this.stateStorage.results
            });
        }
    }

    abort() {
        this.abortController?.abort();
    }

    on(event: EventTypes, listener: (...args: any[]) => any) {
        return this.eventHandler.on(event, listener);
    }

    off(event: EventTypes, listener: (...args: any[]) => any) {
        return this.eventHandler.off(event, listener);
    }

    once(event: EventTypes, listener: (...args: any[]) => any) {
        return this.eventHandler.once(event, listener);
    }

    removeAllListeners() {
        return this.eventHandler.removeAllListeners();
    }

    // TODO: emit: Add to internal object passed to base constructor instead?
    emit(event: EventTypes, ...args: Array<any>) {
        return this.eventHandler.emit(event, ...args, this);
    }
} 

export class AbortError extends Error {
    skipVueHandler = true;
    constructor(message: string, cause?: Error) {
        super(message, {
            cause: cause
        });
    }
}